<template>
  <b-alert show variant="info" class="mt-4 py-4 px-4">
    <h5>
      <b-icon icon="info-circle-fill" class="mr-2"></b-icon>
      Quelques astuces pour un bon enregistrement :
    </h5>
    <ul class="mt-3 pl-4 display-5 info-recorder-list">
      <li>
        L'enregistrement doit durer au maximum 45 secondes
      </li>
      <li>
        Placez-vous dans une zone la moins bruyante possible
      </li>
      <li>
        Laissez deux secondes de blanc après avoir appuyé sur le bouton
      </li>
      <li>
        Parlez calmement et distinctement
      </li>
    </ul>
  </b-alert>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.info-recorder-list li {
  margin: 0.8rem 0;
}
</style>
