<template>
  <b-container>
    <BreadCrumbs :breadcrumbs="$route.matched" />
    <!-- <VueRecordAudio /> -->
    <h-card class="mt-4">
      <b-row class="flex-column align-items-center">
        <b-col v-if="recordStarted">
          <RecorderTimer @finished="stopRecordingDelay" />
        </b-col>
        <b-col v-else cols="5" lg="2" md="3" sm="4" class="my-4">
          <RecorderIllustration :active="recordFinished" />
        </b-col>
        <b-col
          v-if="!recordFinished"
          cols="12"
          sm="8"
          md="6"
          lg="5"
          class="my-4 d-flex justify-content-center"
        >
          <h-button
            v-if="recordStarted"
            @click="stopRecordingFn"
            red
            class="w-100"
          >
            <b-icon icon="stop-fill" class="mr-2"></b-icon>
            Arrêter l'enregistrement
          </h-button>
          <h-button
            :disabled="!hasTags || disableRecord"
            v-else
            @click="startRecordingFn"
            blue
            class="w-100"
          >
            <b-icon icon="mic-fill" class="mr-2"></b-icon>
            Commencer l'enregistrement
          </h-button>
        </b-col>
        <b-col
          v-else
          cols="12"
          sm="8"
          md="6"
          lg="4"
          class="my-4 d-flex flex-column align-items-center"
        >
          <audio
            :src="audioRecord.src"
            :type="audioRecord.mimeType"
            id="audio"
            controls
          ></audio>
          <h-button @click="validRecord" green class="my-3 w-100">
            <b-icon icon="check2" class="mr-2"></b-icon>Valider l'enregistrement
          </h-button>
          <h-button @click="restartRecord" blue class="w-100">
            <b-icon icon="arrow-return-left" class="mr-2"></b-icon> Recommencer
            l'enregistrement
          </h-button>
        </b-col>
        <b-col
          v-if="hasTags && !recordStarted && !recordFinished"
          cols="12"
          md="10"
          lg="8"
        >
          <RecorderHelp />
        </b-col>
        <b-col
          v-if="!hasTags && this.$store.state.message.doneTagReq"
          cols="12"
          md="10"
          lg="8"
        >
          <b-alert show variant="danger" class="mt-4 py-4 px-4 text-center"
            >Ce compte n'a pas de tag pour les messages, contactez le support
            client radioshop.
          </b-alert>
        </b-col>
      </b-row>
    </h-card>
  </b-container>
</template>
<script>
import AudioRecorder from 'audio-recorder-polyfill'
import BreadCrumbs from '@/components/BreadCrumbs'
import RecorderTimer from '@/components/RecorderTimer'
import RecorderIllustration from '@/components/RecorderIllustration'
import RecorderHelp from '@/components/RecorderHelp'

import { mapState } from 'vuex'

export default {
  components: {
    BreadCrumbs,
    RecorderTimer,
    RecorderIllustration,
    RecorderHelp,
  },
  data() {
    return {
      chunks: [],
      disableRecord: false,
      recorder: null,
      options: null,
      recordStarted: false,
      recordFinished: false,
      recordings: [],
      recordingInProgress: false,
      cleanupWhenFinished: true,
    }
  },
  methods: {
    startRecordingFn() {
      if (MediaRecorder.notSupported) {
        alert("Votre navigateur ne supporte pas l'enregistrement audio")
        this.disableRecord = true
      }
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        this.recorder = new MediaRecorder(stream, {
          mimeType: this.options.mimeType,
        })
        this.recorder.start()

        this.recorder.addEventListener('dataavailable', (e) => {
          this.chunks.push(e.data)
          let blob = new Blob(this.chunks, { type: this.options.mimeType })
          const blobUrl = URL.createObjectURL(blob)
          let audioRecordData = {
            size: blob.size,
            type: blob.type,
            src: blobUrl,
            blob: blob,
          }
          this.$store.dispatch('message/_saveMessage', audioRecordData)
        })
      })
      this.recordStarted = true
      this.recordingInProgress = true
    },
    stopRecordingDelay() {
      setTimeout(() => {
        this.stopRecordingFn()
      }, 1000)
    },
    stopRecordingFn() {
      this.recorder.stop() // Remove “recording” icon from browser tab
      this.recorder.stream.getTracks().forEach((i) => i.stop())
      this.recordStarted = false
      this.recordFinished = true
      this.recordingInProgress = false
    },
    restartRecord() {
      this.recordFinished = false
      this.chunks = []
    },
    validRecord() {
      this.$router.push({ name: 'schedule' })
    },
    checkSafari() {
      const isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1
      return isSafari
    },
    iOS() {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      )
    },
    checkRecorderBrowser() {
      if (!window.MediaRecorder || this.iOS() || this.checkSafari()) {
        window.MediaRecorder = AudioRecorder
        this.options = { mimeType: 'audio/wav' }
      } else {
        this.options = { mimeType: 'audio/webm' }
      }

      // This is the case on ios/chrome, when clicking links from within ios/slack (sometimes), etc.
      if (
        !navigator ||
        !navigator.mediaDevices ||
        !navigator.mediaDevices.getUserMedia
      ) {
        alert("Votre navigateur ne supporte pas l'enregistrement audio") // temp: helps when testing for strange issues on ios/safari
        return
      }
    },
  },
  computed: {
    audioRecord() {
      return this.$store.state.message.record
    },
    hasTags() {
      return this.tags != null && this.tags.length > 0
    },
    ...mapState({
      tags: (state) => state.message.tags,
    }),
  },
  created() {
    this.chunks = []
    this.$store.dispatch('message/_getTags')
  },
  mounted() {
    this.checkRecorderBrowser()
  },
}
</script>

<style lang="scss" scoped>
#audio {
  outline: none;
  width: 100%;
}
.vue-audio-recorder {
  width: 100%;
}
</style>
